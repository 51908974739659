<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-24 10:58:49
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-24 11:50:58
-->
<template>
	<div class="history-traffic-class">
		<div class="history-traffic-class-top">
			<div class="history-traffic-class-top-rightTitle">
				<div class="block">
					<span class="demonstration" style="font-size: 18px;margin-right: 10px;">日期选择</span>
					<el-date-picker v-model="dateScope" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" style="margin-right: 10px;">
					</el-date-picker>
				</div>
				<el-button type="primary" @click="queryClick">查询</el-button>
				<el-button type="primary" @click="exportClick">导出</el-button>
			</div>
		</div>
		<div class="history-traffic-class-total">
			<span>水电站</span>
			<el-row>
				<el-col :span="6" style="height:450px;overflow-y: scroll;">
					<div  v-for="(item,index) in imgData" :key="index" @click="imgClick(item)">
						<img style="height: 150px;width: 100%;max-height: 90%;" :src="item.url" />
					</div>
				</el-col>
				<el-col :span="18" style="text-align: center;">
					<img style="height:450px;width: auto;" :src="rightItem.url"/>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dateScope: '',
				imgData: [],
				rightItem: '',
			};
		},
		components: {
		},
		created() {
			this.getImgData();
		},
		watch: {

		},
		methods: {
			getImgData() {
				const that = this;
				for(var i = 0; i < 10; i++) {
					var obj = {
						id: i,
						url: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg0.bjsoyo.com%2Fupfiles%2F20100203%2F20100203171936722.jpg&refer=http%3A%2F%2Fimg0.bjsoyo.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637579628&t=a4ce423682b809956ec60e457582a86a"
					};
					if(i == 0) {
						this.rightItem = obj;
					}
					this.imgData.push(obj);
				}
			},
			imgClick(item) {
				this.rightItem = item;
			},
			/**
			 * @description: 查询事件
			 * @param {*}
			 * @return {*}
			 * @author: Sunwx
			 */
			queryClick() {
				alert("查询");
			},
			/**
			 * @description: 导出事件
			 * @param {*}
			 * @return {*}
			 * @author: Sunwx
			 */
			exportClick() {
				alert("导出");
			},
		}
	}
</script>
<style>
	.history-traffic-class {
		width: 100%;
		height: 100%;
	}

	.history-traffic-class-top {
		width: 100%;
		height: 50PX;
	}

	.history-traffic-class-top-leftTitle {
		width: 200px;
		font-size: 20px;
		float: left;
		margin-top: 8px;
		margin-left: 10px;
	}

	.history-traffic-class-top-rightTitle {
		float: right;
		margin-top: 2px;
		margin-right: 8px;
		display: flex;
	}

	.history-traffic-class-bottom {
		width: 100%;
		height: 300px;
	}

	.left-text {
		float: left;
		background-color: rgb(74, 119, 243);
		color: white;
		font-size: 18px;
		padding: 10px;
		margin-left: 50px;
		margin-top: 20px;
		width: 120px;
	}

	.right-text {
		float: left;
		border: solid 1px gray;
		font-size: 18px;
		padding: 9px;
		margin-top: 20px;
		width: 120px;
	}
</style>
